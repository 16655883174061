<script lang="ts" setup>
import { ref } from "vue"
import { useRoute } from "vue-router"
import dayjs from "dayjs"

import { getChildById, finalizeChild } from "@/services/Child"
import { setCurrentViewContext } from "@/utils/GlobalState"
import { getDischargeChildSchema } from "@/schemas/Child"
import { getReasonForDischargeOptionsResidential } from "@/utils/Options"

import OfflineEnabledForm from "@/components/OfflineEnabledForm.vue"
import ChildHeader from "@/components/children/ChildHeader.vue"

const route = useRoute()
const childId = parseInt(route.params.childId as string)
const siteCmiId = parseInt(route.params.siteCmiId as string)
const existingChild = ref({})
const formIsReady = ref(false)
const successRoute = { name: "ChildList", params: { siteCmiId } }
let dischargeChildSchema

function mungeData(child) {
  if (child.dateOfDischarge) {
      child.dateOfDischarge = dayjs(child.dateOfDischarge).toDate()
    }
    if (child.dateOfDeath) {
      child.dateOfDeath = dayjs(child.dateOfDeath).toDate()
    }
    child.siteId = siteCmiId
    child.id = childId
}

async function getData() {
  const child = await getChildById(childId)
  dischargeChildSchema = getDischargeChildSchema(child)
  const dateFieldNames = ["dateOfDischarge", "dateOfDeath"]
  dateFieldNames.forEach(f => {
    if (child[f]) {
      child[f] = dayjs(child[f]).format("YYYY-MM-DD")
    }
    else {
      child[f] = dayjs().format("YYYY-MM-DD")
    }
  })
  if (child.typeOfService == "residential") {
    dischargeChildSchema.forEach(field => {
      if (field.id == "reasonForDischarge") {
        field.options = getReasonForDischargeOptionsResidential()
      }
    })
  }
  existingChild.value = child
  setCurrentViewContext(child, "child")
  formIsReady.value = true
}

async function localSaveFunc(data) {
  return await finalizeChild(data, "childDischarge")
}

getData()
</script>

<template>
  <article>
    <OfflineEnabledForm
      v-if="formIsReady"
      :page-title="$gettext('Discharge child')"
      :schema="dischargeChildSchema"
      :form-data="existingChild"
      :local-save-func="localSaveFunc"
      :munge-form-data-func="mungeData"
      :success-route="successRoute"
    >
      <template #header>
        <ChildHeader :child="existingChild" />
      </template>
    </OfflineEnabledForm>
  </article>
</template>
