<script lang="ts" setup>
import { ref } from "vue"
import { useRoute, useRouter } from "vue-router"
import dayjs from "dayjs"

import { getChildById, finalizeChild } from "@/services/Child"
import { getSiteByCmiId } from "@/services/Site"
import { setCurrentViewContext } from "@/utils/GlobalState"
import { gettext } from "@/utils/Translation"
import { getChildSchema } from "@/schemas/Child"
import {
  getTypeOfServiceOptions,
  getTypeOfCareCommunityOptions,
  getTypeOfCareResidentialOptions,
} from "@/utils/Options"

import OfflineEnabledForm from "@/components/OfflineEnabledForm.vue"
import ChildHeader from "@/components/children/ChildHeader.vue"

const route = useRoute()
const router = useRouter()
const existingChild = ref(null)
const existingChildWithStringDates = ref(null)
// If null, that means we're creating a new child. Otherwise editing existing.
const childId = route.params.childId ? parseInt(route.params.childId as string) : null
const { $gettext } = gettext
const siteCmiId = parseInt(route.params.siteCmiId as string)

let schema = getChildSchema()
const formIsReady = ref(false)
const pageTitle = ref(null)

function mungeData(child) {
  if (child.dob) {
    child.dob = dayjs(child.dob).toDate()
  }
  if (child.dateOfAdmission) {
    child.dateOfAdmission = dayjs(child.dateOfAdmission).toDate()
  }
  child.siteId = siteCmiId
}

// Based on the site's situation regarding types of services, return a customized schema
// for the child. Also, remove first/last name if *editing* (not adding) a child at a site
// where we cannot see names. And set createdDuringTraining flag for new children!
function setChildSchema(site, childExists: boolean) {
  if (childExists && !site.canSeeNames) {
    schema = schema.filter(field => !["firstName", "lastName"].includes(field.id))
  }
  if (!childExists) {
    schema.push({
      $formkit: "hidden",
      name: "createdDuringTraining",
      value: site.inTrainingMode,
    })
  }
  // Easy case: only one type of service offered; don't ask about child
  if (site.typesOfServices.length == 1) {
    schema.push({
      $formkit: "hidden",
      name: "typeOfService",
      value: site.typesOfServices[0],
    })
    schema.push({
      $formkit: "radio",
      name: "typeOfCare",
      value: "$typeOfCare",
      label: $gettext("Type of care"),
      required: true,
      options: site.typesOfServices[0] == "residential" ? getTypeOfCareResidentialOptions() : getTypeOfCareCommunityOptions(),
    })
  }
  // Case 2: need to ask about type of service, but options for type of care are fixed
  else if (site.typesOfServices.sort() == ["day", "periodical"] ) {
    schema.push({
      $formkit: "radio",
      name: "typeOfService",
      label: $gettext("Type of service"),
      value: "$typeOfService",
      options: getTypeOfServiceOptions().filter(opt => ["day", "periodical"].includes(opt.value)),
      required: true,
    })
    schema.push({
      $formkit: "radio",
      name: "typeOfCare",
      value: "$typeOfCare",
      label: $gettext("Type of care"),
      required: true,
      options: getTypeOfCareCommunityOptions(),
    })
  }
  // Case 3: all/most type of service options are on the table
  else {
    schema.push({
      $formkit: "radio",
      name: "typeOfService",
      id: "typeOfService",
      label: $gettext("Type of service"),
      value: "$typeOfService",
      options: getTypeOfServiceOptions().filter(opt => site.typesOfServices.includes(opt.value)),
      required: true,
    })
    schema.push({
      $formkit: "radio",
      // When the following is enabled, it somehow conflicts with the similar conditional
      // on the `diagnoses` field: `if: "$get(hasDiagnosis).value",`
      // and causes diagnoses' options to disappear and type of care to disappear entirely
      // for existing children with a diagnosis.
      // if: "$get(typeOfService).value",
      name: "typeOfCare",
      id: "typeOfCare",
      label: $gettext("Type of care"),
      value: "$typeOfCare",
      options: {
        if: "$get(typeOfService).value == 'residential'",
        then: getTypeOfCareResidentialOptions(),
        else: getTypeOfCareCommunityOptions(),
      },
      validation: "required",
    })
  }
}

function pushSuccessRouteFunc(newChildId: number) {
  if (childId) {
    // Pre-existing children: send them to the care plan
    router.push({ name: "ChildCarePlan", params: { childId } })
  }
  else if (newChildId) {
    // New children: dedicated "what next" page
    router.push({ name: "ChildJustAdded", params: { childId: newChildId, siteCmiId } })
  }
  else {
    // How did we get here?
    router.push({ name: "SiteHome", params: { siteCmiId } })
  }
}

async function getData() {
  const site = await getSiteByCmiId(siteCmiId)
  if (childId) {
    existingChild.value = await getChildById(childId)
    existingChildWithStringDates.value = await getChildById(childId)
    // Munge dates into strings for use in form
    existingChildWithStringDates.value.dob = dayjs(existingChildWithStringDates.value.dob).format("YYYY-MM-DD")
    if (existingChildWithStringDates.value.dateOfAdmission) {
      existingChildWithStringDates.value.dateOfAdmission = dayjs(existingChildWithStringDates.value.dateOfAdmission).format("YYYY-MM-DD")
    }
    setCurrentViewContext(existingChild.value, "child")
    pageTitle.value = $gettext("Update child")
  }
  else {
    pageTitle.value = $gettext("Add a child")
    setCurrentViewContext(site, "site")
  }
  setChildSchema(site, Boolean(childId))
  formIsReady.value = true
}

getData()
</script>

<template>
  <article>
    <OfflineEnabledForm
      v-if="formIsReady"
      :page-title="pageTitle"
      :schema="schema"
      :form-data="existingChildWithStringDates"
      :local-save-func="finalizeChild"
      :munge-form-data-func="mungeData"
      :push-success-route-func="pushSuccessRouteFunc"
    >
      <template #header>
        <ChildHeader
          v-if="existingChild"
          :child="existingChild"
        />
      </template>
    </OfflineEnabledForm>
  </article>
</template>
