<script lang="ts" setup>
  import { useRoute } from "vue-router"
  import { ref, computed } from "vue"
  import { useWindowSize } from '@vueuse/core'

  import { gettext } from "@/utils/Translation"
  import { getChildById } from "@/services/Child"
  import { updateChildsAssessmentsFromServer } from "@/services/Child"
  import { getLastSavedGrowthAssessmentForChild } from "@/services/GrowthAssessment"
  import { getLastSavedFeedingScreeningForChild, getLastChildBestPracticeAssessmentForChild } from "@/services/Feeding"
  import { getLastSavedAnemiaAssessmentForChild } from "@/services/AnemiaAssessment"
  import { getLastDevelopmentalScreeningForChild } from "@/services/DevelopmentalScreening"
  import { DevelopmentalScreening } from "@/models/DevelopmentalScreening"
  import {
    setCurrentViewContext,
    getLastUpdateInfo,
    areCachedDataStale,
    showFlashMessage,
    setStalenessNotificationTime,
  } from "@/utils/GlobalState"
  import { Child } from "@/models/Child"
  import { getSiteByCmiId } from "@/services/Site"
  import useServerRefresher from "@/composables/useServerRefresher"

  import PageTitleWithRefresh from "@/components/PageTitleWithRefresh.vue"
  import ChildHeader from "@/components/children/ChildHeader.vue"
  import LastSynced from "@/components/LastSynced.vue"
  import RecommendationsReferrals from "@/components/assessments/RecommendationsReferrals.vue"
  import StickyCard from "@/components/assessments/StickyCard.vue"
  import AssessmentNote from "@/components/assessments/AssessmentNote.vue"
  import PageLoading from "@/components/PageLoading.vue"
  import IconRightArrow from "@/components/svg/IconRightArrow.vue"

  const { width } = useWindowSize()
  const tabletMinWidth = 625 // Should correspond with $tablet defined in _main.css
  const orderedModuleNames = ["growth", "bestPractices", "feeding", "anemia", "developmentalScreening"]
  const route = useRoute()
  const childId = parseInt(route.params.childId as string)
  const { $gettext } = gettext
  const { refreshUnderway, serverRefresher } = useServerRefresher()
  const staleNotificationQueryKey = `childAssessments-${childId}`

  // Refs
  const pageReady = ref(false)
  const child = ref(null)
  const site = ref(null)
  const growthAssessment = ref(null)
  const feedingScreening = ref(null)
  const anemiaAssessment = ref(null)
  const developmentalScreening = ref(null)
  const bestPracticesAssessment = ref(null)
  const referrals = ref([])
  const reportCard = ref(null)
  const assessmentNotes = ref(null)
  const expandedAssessmentNotes = ref({}) // Which assessment notes are expanded? (initially: none)
  const nextAssessmentDates = ref(null)
  const lastSynced = ref(undefined)
  const isTabletOrWider = computed(() => width.value > tabletMinWidth)

  function setReferrals() {
    const results = []
    if (growthAssessment.value?.referrals) {
      results.push(...growthAssessment.value.referrals)
    }
    if (anemiaAssessment.value?.referrals) {
      results.push(...anemiaAssessment.value.referrals)
    }
    if (feedingScreening.value?.referrals) {
      results.push(...feedingScreening.value.referrals)
    }
    const devScreeningReferrals = developmentalScreening.value?.getReferrals()
    if (devScreeningReferrals?.length) {
      results.push(...devScreeningReferrals)
    }
    referrals.value = results
  }

  // Return an object representing the growth module's report card contribution
  function getGrowthReportCard() {
    if (growthAssessment.value) {
      return {
        id: "growth",
        label: $gettext("Growth"),
        urlParams: { name: "GrowthRecommendations", params: { assessmentId: growthAssessment.value.id, childId } },
        ...growthAssessment.value.reportCard
       }
    }
    else {
      return {
        id: "growth",
        label: $gettext("Growth"),
        stoplightColor: "incomplete",
        statements: [$gettext("Not yet assessed")],
      }
    }
  }

  // Return an object representing the feeding module's report card contribution
  function getFeedingReportCard() {
    if (feedingScreening.value) {
      return {
        id: "feeding",
        label: $gettext("Feeding"),
        urlParams: { name: "FeedingScreeningRecommendations", params: { assessmentId: feedingScreening.value.id, childId } },
        ...feedingScreening.value.reportCard
       }
    }
    else {
      return {
        id: "feeding",
        label: $gettext("Feeding"),
        stoplightColor: "incomplete",
        statements: [$gettext("Not yet assessed")],
      }
    }
  }

  // Return an object representing the anemia module's report card contribution
  function getAnemiaReportCard() {
    if (anemiaAssessment.value) {
      return {
        id: "anemia",
        label: $gettext("Anemia"),
        urlParams: { name: "AnemiaRecommendations", params: { assessmentId: anemiaAssessment.value.id, childId } },
        ...anemiaAssessment.value.reportCard
       }
    }
    else {
      return {
        id: "anemia",
        label: $gettext("Anemia"),
        stoplightColor: "incomplete",
        statements: [$gettext("Not yet assessed")],
      }
    }
  }

  // Return an object representing the developmental screening module's report card contribution
  function getDevelopmentalScreeningReportCard() {
    let stoplightColor = "incomplete"
    let statements = [$gettext("Not yet assessed")]
    let urlParams = null

    if (developmentalScreening.value) {
      urlParams = { name: "DevelopmentalScreeningRecommendations", params: { assessmentId: developmentalScreening.value.id, childId } }
      stoplightColor = developmentalScreening.value.reportCard.stoplightColor
      statements = developmentalScreening.value.reportCard.statements
    }
    else if (DevelopmentalScreening.isChildTooYoung(child.value)) {
      statements = [$gettext("Too young")]
    }
    else if (DevelopmentalScreening.isChildTooOld(child.value)) {
      statements = [$gettext("Aged out")]
    }
    return {
      id: "developmentalScreening",
      label: $gettext("Developmental Screening"),
      stoplightColor,
      statements,
      urlParams,
    }
  }

  function getBestPracticesReportCard() {
    if (bestPracticesAssessment.value) {
      return {
        id: "bestPractices",
        label: $gettext("Best Practices"),
        urlParams: { name: "PracticesRecommendations", params: { assessmentId: bestPracticesAssessment.value.id, childId } },
        ...bestPracticesAssessment.value.reportCard
       }
    }
    else {
      return {
        id: "bestPractices",
        label: $gettext("Best Practices"),
        stoplightColor: "incomplete",
        statements: [$gettext("Not yet assessed")],
      }
    }
  }


  function setNextAssessmentDates() {
    const results = []
    if (site.value.growthEnabled) {
      results.push({
        id: "growth",
        label: $gettext("Growth"),
        text: child.value.nextGrowthAssessmentDate?.toLocaleDateString() || $gettext("As soon as possible"),
        urlParams: { name: "ChildConfirmation",  params: { siteCmiId: site.value.cmiId, childId, assessmentType: 'g' } },
      })
    }
    if (site.value.isChildMealtimeBestPracticeAssessmentEnabled) {
      results.push({
        id: "bestPractices",
        label: $gettext("Best Practices"),
        text: child.value.nextBestPracticesAssessmentDate?.toLocaleDateString() || $gettext("As soon as possible"),
        urlParams: { name: "ChildConfirmation",  params: { siteCmiId: site.value.cmiId, childId, assessmentType: 'b' } },
      })
    }
    if (site.value.isFeedingScreeningEnabled) {
      let text: string
      if (child.value.nextMealtimeAssessmentDate) {
        text = child.value.nextMealtimeAssessmentDate.toLocaleDateString()
      }
      else if (child.value.nextMealtimeAssessmentType === "aged-out") {
        text = $gettext("Aged out")
      }
      else {
        text = $gettext("As soon as possible")
      }
      results.push({
        id: "feeding",
        label: $gettext("Feeding"),
        text,
        urlParams: { name: "ChildConfirmation",  params: { siteCmiId: site.value.cmiId, childId, assessmentType: 'f' } },
      })
    }
    if (site.value.anemiaEnabled) {
      results.push({
        id: "anemia",
        label: $gettext("Anemia"),
        text: child.value.nextAnemiaAssessmentDate?.toLocaleDateString() || $gettext("As soon as possible"),
        urlParams: { name: "ChildConfirmation",  params: { siteCmiId: site.value.cmiId, childId, assessmentType: "a" } },
      })
    }
    if (site.value.earlyidEnabled) {
      let showLink = true
      let text = child.value.nextEarlyidAssessmentDate?.toLocaleDateString()
      if (!text) {
        if (DevelopmentalScreening.isChildTooYoung(child.value)) {
          text = $gettext("Too young")
          showLink = false
        }
        else if (DevelopmentalScreening.isChildTooOld(child.value)) {
          text = $gettext("Aged out")
          showLink = false
        }
        else {
          text = $gettext("As soon as possible")
        }
      }
      results.push({
        id: "earlyid",
        label: $gettext("Developmental Screening"),
        text,
        urlParams: showLink ? { name: "ChildConfirmation",  params: { siteCmiId: site.value.cmiId, childId, assessmentType: 'd' } } : null,
    })
    }
    nextAssessmentDates.value = results
  }

  // Add each module to the report card as appropriate in the context of this site.
  async function attemptReportCardConstruction() {
    const results = {}
    if (site.value.growthEnabled) {
      results["growth"] = getGrowthReportCard()
    }
    if (site.value.isFeedingScreeningEnabled) {
      results["feeding"] = getFeedingReportCard()
    }
    if (site.value.anemiaEnabled) {
      results["anemia"] = getAnemiaReportCard()
    }
    if (site.value.earlyidEnabled) {
      results["developmentalScreening"] = getDevelopmentalScreeningReportCard()
    }
    if (site.value.isChildMealtimeBestPracticeAssessmentEnabled) {
      results["bestPractices"] = getBestPracticesReportCard()
    }
    reportCard.value = results
    lastSynced.value = await getLastUpdateInfo({ type: "childAssessments", localItemId: childId })
  }

  function reportCardIterator() {
    const results = []
    for (let i=0; i < orderedModuleNames.length; i++) {
      const name = orderedModuleNames[i]
      if (name in reportCard.value) {
        results.push(reportCard.value[name])
      }
    }
    return results
  }

  function setAssessmentNotes() {
    const getNonEmptyListOrNull = candidate => {
      return candidate && candidate.length ? candidate : null
    }
    const results = {}
    if (site.value.growthEnabled) {
      expandedAssessmentNotes.value["growth"] = false
      results["growth"] = {
        id: "growth",
        label: $gettext("Growth"),
        notes: getNonEmptyListOrNull(growthAssessment.value?.notes),
      }
      if (growthAssessment.value && results["growth"].notes) {
        results["growth"].urlParams = { name: "GrowthRecommendations", params: { assessmentId: growthAssessment.value.id, childId } }
      }
    }
    if (site.value.isChildMealtimeBestPracticeAssessmentEnabled) {
      expandedAssessmentNotes.value["bestPractices"] = false
      results["bestPractices"] = {
        id: "bestPractices",
        label: $gettext("Best Practices"),
        notes: getNonEmptyListOrNull(bestPracticesAssessment.value?.notes),
      }
      if (bestPracticesAssessment.value && results["bestPractices"].notes) {
        results["bestPractices"].urlParams = { name: "PracticesRecommendations", params: { assessmentId: bestPracticesAssessment.value.id, childId } }
      }
    }
    if (site.value.isFeedingScreeningEnabled) {
      expandedAssessmentNotes.value["feeding"] = false
      results["feeding"] = {
        id: "feeding",
        label: $gettext("Feeding"),
        notes: getNonEmptyListOrNull(feedingScreening.value?.notes),
      }
      if (feedingScreening.value && results["feeding"].notes) {
        results["feeding"].urlParams = { name: "FeedingScreeningRecommendations", params: { assessmentId: feedingScreening.value.id, childId } }
      }
    }
    if (site.value.anemiaEnabled) {
      expandedAssessmentNotes.value["anemia"] = false
      results["anemia"] = {
        label: $gettext("Anemia"),
        id: "anemia",
        notes: getNonEmptyListOrNull(anemiaAssessment.value?.notes),
      }
      if (anemiaAssessment.value && results["anemia"].notes) {
        results["anemia"].urlParams = { name: "AnemiaRecommendations", params: { assessmentId: anemiaAssessment.value.id, childId } }
      }
    }
    if (site.value.earlyidEnabled) {
      expandedAssessmentNotes.value["developmentalScreening"] = false
      results["developmentalScreening"] = {
        id: "developmentalScreening",
        label: $gettext("Developmental Screening"),
        notes: getNonEmptyListOrNull(developmentalScreening.value?.notes),
      }
      if (developmentalScreening.value && results["developmentalScreening"].notes) {
        results["developmentalScreening"].urlParams = { name: "DevelopmentalScreeningRecommendations", params: { assessmentId: developmentalScreening.value.id, childId } }
      }
    }
    assessmentNotes.value = results
  }

  function assessmentNotesIterator() {
    const results = []
    for (let i=0; i < orderedModuleNames.length; i++) {
      const name = orderedModuleNames[i]
      if (name in assessmentNotes.value) {
        results.push(assessmentNotes.value[name])
      }
    }
    return results
  }

  // Set refs for each of the relevant assessment types with the most recent
  // of each type. Resolves to a boolean indicating if there were any in the first
  // place.
  async function getLocalAssessments() {
    let haveAssessments = false
    if (site.value.growthEnabled) {
      growthAssessment.value = await getLastSavedGrowthAssessmentForChild(child.value)
      if (growthAssessment.value) {
        await growthAssessment.value.processAssessment()
        haveAssessments = true
      }
    }
    if (site.value.isFeedingScreeningEnabled) {
      feedingScreening.value = await getLastSavedFeedingScreeningForChild(child.value)
      if (feedingScreening.value) {
        haveAssessments = true
      }
    }
    if (site.value.anemiaEnabled) {
      anemiaAssessment.value = await getLastSavedAnemiaAssessmentForChild(child.value)
      if (anemiaAssessment.value) {
        await anemiaAssessment.value.processAssessment()
        haveAssessments = true
      }
    }
    if (site.value.earlyidEnabled) {
      developmentalScreening.value = await getLastDevelopmentalScreeningForChild(child.value)
      if (developmentalScreening.value) {
        haveAssessments = true
      }
    }
    if (site.value.isChildMealtimeBestPracticeAssessmentEnabled) {
      bestPracticesAssessment.value = await getLastChildBestPracticeAssessmentForChild(child.value)
      if (bestPracticesAssessment.value) {
        haveAssessments = true
      }
    }
    return haveAssessments
  }

  async function getAssessmentsFromServer(wasManuallyTriggered = false) {
    const updateAssessments = () => updateChildsAssessmentsFromServer(childId, site.value)
    await serverRefresher(updateAssessments, [childId], staleNotificationQueryKey, wasManuallyTriggered)
      .catch(error => {
        if (error.name === "CONNECTIVITY_REQUIRED") {
          const msg = $gettext("You do not appear to be online.")
          let details = ""
          if (lastSynced.value) {
            details = $gettext("The information shown here was last updated %{ date }.", { date: lastSynced.value })
          }
          else {
            details = $gettext("The information has never been updated from the server.")
          }
          showFlashMessage({ msg: `${msg} ${details}`, class: "is-warning", appearanceDelay: 0 })
          setStalenessNotificationTime(staleNotificationQueryKey)
        }
        else {
          throw error
        }
      })
  }

  async function hardRefresh() {
    await getAssessmentsFromServer()
    await getLocalAssessments()
    await attemptReportCardConstruction()
    setReferrals()
  }

  async function getData() {
    child.value = new Child(await getChildById(childId))
    setCurrentViewContext(child, "child")
    site.value = await getSiteByCmiId(child.value.siteId)
    // As long as we have the child and the site, we should have spinners
    // for each of the page components while they flesh themselves out.
    pageReady.value = true

    let serverHasBeenPolled = false

    if (await areCachedDataStale({ type: "childAssessments", localItemId: childId })) {
      await getAssessmentsFromServer()
      serverHasBeenPolled = true
    }

    // Semi-complicated song and dance to avoid hitting the API twice
    // if we don't need to.
    const haveAssessments = await getLocalAssessments()
    if (!haveAssessments && !serverHasBeenPolled) {
      await hardRefresh()
    }
    else {
      await attemptReportCardConstruction()
      setReferrals()
    }
    setNextAssessmentDates()
    setAssessmentNotes()
  }

  getData()
</script>
<template>
  <article
    v-if="pageReady"
    id="child-care-plan"
    class="recommendations"
  >
    <ChildHeader
      v-if="child"
      :child="child"
    />

    <div
      v-if="child && child.createdDuringTraining"
      class="training-mode-label"
    >
      {{ $gettext("Child Created During Training") }}
    </div>
    <PageTitleWithRefresh
      :title="$gettext('Care Plan')"
      :refresh-function="hardRefresh"
      :refresh-underway="refreshUnderway"
    />

    <!-- Referrals -->
    <RecommendationsReferrals :referrals="referrals" />

    <!-- Most Recent Assessments (FKA Report Card) -->
    <section
      v-if="isTabletOrWider"
      class="tablet-plus report-card"
    >
      <h2>{{ $gettext("Most Recent Assessments") }}</h2>
      <div
        v-if="reportCard"
        class="columns"
      >
        <div
          v-for="module in reportCardIterator()"
          :id="`report-card-${module.id}`"
          :key="module.id"
          class="column card"
        >
          <header class="card-header">
            <div class="card-header-icon">
              <span class="icon">
                <span :class="['stoplight', module.stoplightColor]" />
              </span>
            </div>
            <h3 class="card-header-title">
              {{ module.label }}
            </h3>
          </header>
          <div class="card-content">
            <div class="content">
              <ul class="list">
                <li
                  v-for="statement in module.statements"
                  :key="statement"
                >
                  {{ statement }}
                </li>
              </ul>
              <div class="has-text-centered">
                <router-link
                  v-if="module.urlParams"
                  :to="module.urlParams"
                  class="text-link"
                >
                  {{ $gettext("See recommendations") }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PageLoading
        v-else
        size="small"
      />
    </section>
    <StickyCard
      v-else
      extra-section-classes="report-card"
    >
      <template #title>
        <h2>{{ $gettext("Most Recent Assessments") }}</h2>
      </template>
      <template #cardContent>
        <template v-if="reportCard">
          <div
            v-for="module in reportCardIterator()"
            :id="`rc-${module.id}`"
            :key="module.id"
            class="sc-item"
          >
            <router-link
              v-if="module.urlParams"
              :to="module.urlParams"
              class="sc-header right-arrow"
            >
              <span :class="['stoplight', module.stoplightColor]" />
              <h3>
                {{ module.label }}
              </h3>
              <IconRightArrow />
            </router-link>
            <div
              v-else
              class="sc-header"
            >
              <span :class="['stoplight', module.stoplightColor]" />
              <h3>
                {{ module.label }}
              </h3>
            </div>
            <ul class="list">
              <li
                v-for="statement in module.statements"
                :key="statement"
              >
                {{ statement }}
              </li>
            </ul>
          </div>
        </template>
        <PageLoading
          v-else
          wrapper-classes="p-4"
          size="small"
        />
      </template>
    </StickyCard>

    <!-- Assessment Notes -->
    <section
      v-if="isTabletOrWider"
      class="tablet-plus assessment-notes"
    >
      <h2>
        {{ $gettext("Assessment notes") }}
      </h2>
      <div
        v-if="assessmentNotes"
        class="columns"
      >
        <div
          v-for="module in assessmentNotesIterator()"
          :id="`an-${module.id}`"
          :key="`an-${module.id}`"
          class="column card"
        >
          <header class="card-header">
            <h3 class="card-header-title">
              {{ module.label }}
              <span
                v-if="module.notes"
                class="muted"
              >
                ({{ module.notes.length }})
              </span>
            </h3>
          </header>
          <div
            v-if="module.notes"
            class="card-content"
          >
            <div
              :class="{'notes-container': true, 'clipped': !expandedAssessmentNotes[module.id]}"
            >
              <template
                v-for="note in module.notes"
                :key="note.id"
              >
                <AssessmentNote :note="note" />
              </template>
            </div>
            <a
              class="interactive-link small"
              @click="expandedAssessmentNotes[module.id] = !expandedAssessmentNotes[module.id]"
            >
              {{ expandedAssessmentNotes[module.id] ? $gettext("Show less") : $gettext("Show more") }}
            </a>
            <div
              v-if="module.urlParams"
              class="has-text-centered mt-4"
            >
              <router-link
                :to="module.urlParams"
                class="text-link"
              >
                {{ $gettext("See recommendations") }}
              </router-link>
            </div>
          </div>
          <div
            v-else
            class="card-content"
          >
            <em class="muted">
              {{ $gettext("No assessment notes have been recorded for this child.") }}
            </em>
          </div>
        </div>
      </div>
      <PageLoading
        v-else
        size="small"
      />
    </section>
    <StickyCard
      v-else
      extra-section-classes="assessment-notes"
    >
      <template #title>
        {{ $gettext("Assessment notes") }}
      </template>
      <template #cardContent>
        <div v-if="assessmentNotes">
          <div
            v-for="module in assessmentNotesIterator()"
            :id="`an-${module.id}`"
            :key="`an-${module.id}`"
            class="sc-item"
          >
            <router-link
              v-if="module.urlParams"
              :to="module.urlParams"
              class="sc-header right-arrow"
            >
              <h3>
                {{ module.label }}
                <span
                  v-if="module.notes"
                  class="muted"
                >
                  ({{ module.notes.length }})
                </span>
              </h3>
              <IconRightArrow />
            </router-link>
            <div
              v-else
              class="sc-header"
            >
              <h3>
                {{ module.label }}
              </h3>
            </div>
            <template v-if="module.notes">
              <div
                :class="{'notes-container': true, 'clipped': !expandedAssessmentNotes[module.id]}"
              >
                <template
                  v-for="note in module.notes"
                  :key="note.id"
                >
                  <AssessmentNote :note="note" />
                </template>
              </div>
              <a
                class="interactive-link small"
                @click="expandedAssessmentNotes[module.id] = !expandedAssessmentNotes[module.id]"
              >
                {{ expandedAssessmentNotes[module.id] ? $gettext("Show less") : $gettext("Show more") }}
              </a>
            </template>
            <div
              v-else
              class="is-italic"
            >
              {{ $gettext("No assessment notes have been recorded for this child.") }}
            </div>
          </div>
        </div>
        <PageLoading
          v-else
          wrapper-classes="p-4"
          size="small"
        />
      </template>
    </StickyCard>

    <!-- Next Assessments -->
    <section
      v-if="isTabletOrWider"
      class="tablet-plus next-assessments"
    >
      <h2>
        {{ $gettext("Next scheduled assessments") }}
      </h2>
      <div
        v-if="nextAssessmentDates"
        class="columns"
      >
        <div
          v-for="card in nextAssessmentDates"
          :key="card.id"
          :class="['column', 'card', card.id]"
        >
          <header class="card-header">
            <h3 class="card-header-title">
              {{ card.label }}
            </h3>
          </header>
          <div class="card-content">
            <p>{{ card.text }}</p>
            <div class="has-text-centered">
              <router-link
                v-if="card.urlParams"
                :to="card.urlParams"
                class="button is-primary"
              >
                {{ $gettext("Assess now") }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <PageLoading
        v-else
        size="small"
      />
    </section>
    <StickyCard v-else>
      <template #title>
        {{ $gettext("Next scheduled assessments") }}
      </template>
      <template #cardContent>
        <template v-if="nextAssessmentDates">
          <div
            v-for="card in nextAssessmentDates"
            :key="card.label"
            class="sc-item"
          >
            <div class="sc-header">
              <div>
                <h3>{{ card.label }}</h3>
                <p class="muted">
                  {{ card.text }}
                </p>
              </div>
              <router-link
                v-if="card.urlParams"
                :to="card.urlParams"
                class="button is-primary is-small"
              >
                {{ $gettext("Assess now") }}
              </router-link>
            </div>
          </div>
        </template>
        <PageLoading
          v-else
          wrapper-classes="p-4"
          size="small"
        />
      </template>
    </StickyCard>
    <LastSynced :last-synced="lastSynced" />
  </article>
  <article v-else>
    <h1 class="title">
      {{ $gettext("Care Plan") }}
    </h1>
    <PageLoading :with-text="true" />
  </article>
</template>
<style scoped lang="scss">
  .training-mode-label {
    font-size: 0.75rem;
    text-align: center;
    margin: -1rem 0 1rem;
  }
  .referral-list {
    padding: 1em;
  }

  a h3 {
    color: hsl(0, 0%, 48%);;
  }
  h3 .muted {
    font-weight: normal;
    margin-left: 0.25em;
  }
  .clipped {
    max-height: 3em;
    overflow: clip;
    /* XXX: This mask doesn't play well with the sticky card title. It causes the content of the masked element to float above the sticky header. */
    --mask: linear-gradient(to bottom,
        rgba(0,0,0, 1) 0,   rgba(0,0,0, 1) 40%,
        rgba(0,0,0, 0) 95%, rgba(0,0,0, 0) 0
    ) 100% 50% / 100% 100% repeat-x;
    mask: var(--mask);
    /* This width correspond with $tablet */
    @media (min-width: 625px) {
      max-height: 5em;
    }
  }

  /* When cards are side-by-side */
  /* This width correspond with $tablet */
  @media (min-width: 625px) {
    .card {
      margin: 0 1rem;
    }
  }
  .tablet-plus {
    margin-bottom: 2em;
    .next-assessments,
    .assessment-notes {
      h3 {
        font-size: 1.25rem;
      }
    }
  }
</style>
