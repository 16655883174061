import dayjs from "dayjs"

import {
  getCauseOfDeathOptions,
  getDiagnosisOptions,
  getReasonForDischargeOptionsCommunity,
  getSexOptions,
  getYesNoOptions,
} from "@/utils/Options"
import { gettext } from "@/utils/Translation"

const { $gettext } = gettext

export function getChildSchema() {
  const tomorrow = dayjs().add(1, "d").format("YYYY-MM-DD")
  // Won't register children older than 18 years: that's when they stop seeing
  // pediatricians; WAG ceases to apply.
  const min = dayjs().subtract(18, "y").format("YYYY-MM-DD")
  const max = dayjs().format("YYYY-MM-DD")
  return [
    {
      $formkit: "hidden",
      name: "id",
      value: "$id",
    },
    {
      $formkit: "hidden",
      name: "cmiId",
      value: "$cmiId",
    },
    {
      $formkit: "text",
      name: "firstName",
      id: "firstName",
      value: "$firstName",
      label: $gettext("First Name"),
      validation: "required",
    },
    {
      $formkit: "text",
      name: "lastName",
      id: "lastName",
      value: "$lastName",
      label: $gettext("Surname/Family Name"),
    },
    {
      $formkit: "radio",
      name: "sex",
      id: "sex",
      value: "$sex",
      label: $gettext("Sex"),
      options: getSexOptions(),
      validation: "required",
    },
    {
      $formkit: "date",
      name: "dob",
      id: "dob",
      value: "$dob",
      label: $gettext("Date of Birth"),
      min, max,
      validation: `required|date_before:${tomorrow}`,
      "validation-messages": { date_before: $gettext("This date cannot be in the future.") },
    },
    {
      $formkit: "radio",
      name: "dobEstimated",
      id: "dobEstimated",
      value: "$dobEstimated",
      label: $gettext("Is date of birth estimated?"),
      options: getYesNoOptions(),
      validation: "required",
    },
    {
      $formkit: "radio",
      id: "hasDiagnosis",
      name: "hasDiagnosis",
      value: "$hasDiagnosis",
      label: $gettext("Does the child have a special healthcare need?"),
      options: getYesNoOptions(),
      validation: "required",
    },
    {
      $formkit: "checkbox",
      if: "$get(hasDiagnosis).value",
      name: "diagnoses",
      id: "diagnoses",
      value: "$diagnoses",
      label: $gettext("Diagnoses"),
      options: getDiagnosisOptions(),
      validation: "required",
      "validation-messages": {
        required: $gettext("Because you indicated that the child has a special healthcare need, please specify one or more diagnoses."),
      },
    },
    {
      $formkit: "text",
      name: "contactInformation",
      id: "contactInformation",
      value: "$contactInformation",
      label: $gettext("Caregiver contact information"),
      help: $gettext("Include any details, such as caregiver's name, that are helpful for contacting them to remind them about upcoming assessments. Record phone number separately. Optional."),
    },
    {
      $formkit: "tel",
      name: "caregiverPhoneNumber",
      id: "caregiverPhoneNumber",
      value: "$caregiverPhoneNumber",
      label: $gettext("Caregiver phone number"),
      help: $gettext("Only include the phone number itself. Other contact information should be stored separately. Optional."),
    },
    {
      $formkit: "date",
      name: "dateOfAdmission",
      id: "dateOfAdmission",
      value: "$dateOfAdmission",
      max,
      label: $gettext("If known, when did the child first begin receiving services at this site?"),
      // Ideally use the validation commented out below (after dob, but not in future),
      // but it was not validating properly.
      // For references to other fields see https://github.com/formkit/formkit/issues/362
      // validation: `$: "date_after:" + $dob + "|date_before:${tomorrow}"`,
      validation: `date_before:${tomorrow}`,
      "validation-messages": {
        date_before: $gettext("This date cannot be in the future."),
        // date_after: $gettext("This date cannot be before the child's date of birth.")
      },
    },
  ]
}

export function getDischargeChildSchema(child) {
  const min = dayjs(child.dateOfAdmission || child.dob).format("YYYY-MM-DD")
  const max = dayjs().format("YYYY-MM-DD")
  const tomorrow = dayjs().add(1, "d").format("YYYY-MM-DD")
  return [
    {
      $formkit: "date",
      name: "dateOfDischarge",
      id: "dateOfDischarge",
      value: "$dateOfDischarge",
      min, max,
      label: $gettext("Date of discharge"),
      validation: `required|date_before:${tomorrow}`,
      "validation-messages": { date_before: $gettext("This date cannot be in the future.") },
    },
    {
      $formkit: "select",
      id: "reasonForDischarge",
      name: "reasonForDischarge",
      value: "$reasonForDischarge",
      label: $gettext("Reason for discharge"),
      options: getReasonForDischargeOptionsCommunity(),  // This might be swapped out later.
      validation: "required",
      "inner-class": "select",
    },
    {
      $formkit: "text",
      if: "$get(reasonForDischarge).value == 'other'",
      name: "otherReasonForDischarge",
      id: "otherReasonForDischarge",
      value: "$otherReasonForDischarge",
      label: $gettext("Other reason for discharge"),
      validation: "required",
    },
    {
      $formkit: "date",
      if: "$get(reasonForDischarge).value == 'deceased'",
      name: "dateOfDeath",
      id: "dateOfDeath",
      value: "$dateOfDeath",
      label: $gettext("Date of death"),
      min, max,
      validation: `required|date_before:${tomorrow}`,
      "validation-messages": { date_before: $gettext("This date cannot be in the future.") },
    },
    {
      $formkit: "select",
      if: "$get(reasonForDischarge).value == 'deceased'",
      id: "causeOfDeath",
      name: "causeOfDeath",
      value: "$causeOfDeath",
      label: $gettext("Cause of death"),
      options: getCauseOfDeathOptions(),
      validation: "required",
      "inner-class": "select",
    },
    {
      $formkit: "text",
      if: "$get(causeOfDeath).value == 'other'",
      name: "otherCauseOfDeath",
      id: "otherCauseOfDeath",
      value: "$otherCauseOfDeath",
      label: $gettext("Other cause of death"),
      validation: "required",
    },
  ]
}
